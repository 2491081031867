@import 'assets/scss/variables.scss';
@import 'assets/scss/mixin.scss';

.card-container {
  border-radius: 10px;
  background: $white-color;
  box-shadow: 0 8px 16px 0 rgb(0 65 191 / 16%);
  padding: 1rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title {
      color: $text-color;
      margin: 0;

      @include fs-20;
      @include font-600;
    }

    .edit-icon {
      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;
        vertical-align: unset;
      }
    }
  }
}
