@import 'assets/scss/variables.scss';
@import 'assets/scss/mixin.scss';

.create-budget {
  .modal-dialog {
    max-width: 30%;
  }

  &__body {
    &.modal-body {
      @include app-width(padding, 36);
    }

    &__header {
      color: $theme-color;
      margin-bottom: 1.5rem;

      @include fs-24;
      @include font-600;

      .budget-name {
        // color: $error-color;
        color: rgb(50 177 50);
        white-space: break-spaces;
      }
    }

    &__form {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    &__action {
      margin-top: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
  }
}
