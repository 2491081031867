@import 'assets/scss/mixin.scss';
@import 'assets/scss/variables.scss';

.user-payments {
  &__content {
    &__col {
      display: flex;
      flex-direction: column;

      @include app-width(gap, 24);

      .address-info {
        width: 100%;
        height: max-content;
        display: flex;
        flex-direction: column;

        @include app-width(gap, 24);

        .form-label {
          &__optional-text {
            color: $semi-grey-color;

            @include fs-14;
            @include font-400;
          }
        }
      }
    }
  }
}
