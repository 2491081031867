@import 'assets/scss/variables.scss';

.home-screen-wrapper {
  width: 100vw;

  .main-container {
    width: 100%;
    height: calc(100vh - 5rem);
    background-color: $off-white-color;
    overflow: hidden;
  }
}
