@import 'assets/scss/mixin.scss';
@import 'assets/scss/variables.scss';

.form-input-group {
  position: relative;

  &__label {
    color: $text-color;

    @include fs-20;
    @include font-400;
  }

  & > #{&}__input {
    background: $light-grey-color;
    color: $black-color;
    border: 1px solid transparent;
    border-radius: 5px;

    &input {
      height: 2.5rem;
    }

    &:read-only {
      box-shadow: none;
      border: 1px solid transparent;
      background: $light-grey-color;
    }

    @include fs-16;
    @include font-400;

    &::placeholder {
      color: $placeholder-color;

      @include fs-16;
      @include font-400;
    }
  }

  .close-icon {
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 0;
  }

  .invalid-feedback {
    color: $error-color;

    @include fs-12;
    @include font-400;

    .error-icon {
      margin-right: 5px;
    }
  }
}

.comment-label {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  top: 5px;
  position: relative;
}

.form-input-group-collapsed-comment-label {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  top: 5px;
  position: relative;
}

.label-cls {
  // font-size: calc(100vw * 20 / 1440);
  font-size: 16px;
  margin-top: 5px;
}

.form-input-group-expanded {
  width: 100%;
  display: flex;
  gap: 10px;
}

.form-input-group-expanded-comment-label {
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  top: 5px;
  position: relative;
}

.form-input-group-collapsed {
  width: 100%;

  // display: flex;
  // gap: 10px;
}
