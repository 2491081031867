@import 'assets/scss/variables.scss';

.grid-loader {
  width: 57px;
  display: inline-block;

  &__block {
    display: inline-block;
    background-color: $link-color;
    width: 15px;
    height: 15px;
    margin: 2px;
    border-radius: 100%;
  }

  &__block-1 {
    animation: 1.0695s ease 0.2695s infinite normal none running grid-loader;
  }

  &__block-2 {
    animation: 1.5693s ease 0.7693s infinite normal none running grid-loader;
  }

  &__block-3 {
    animation: 1.467s ease 0.667s infinite normal none running grid-loader;
  }

  &__block-4 {
    animation: 1.545s ease 0.745s infinite normal none running grid-loader;
  }

  &__block-5 {
    animation: 0.7855s ease -0.0145s infinite normal none running grid-loader;
  }

  &__block-6 {
    animation: 1.0008s ease 0.2008s infinite normal none running grid-loader;
  }

  &__block-7 {
    animation: 1.3799s ease 0.5799s infinite normal none running grid-loader;
  }

  &__block-8 {
    animation: 0.7397s ease -0.0603s infinite normal none running grid-loader;
  }

  &__block-9 {
    animation: 0.8396s ease 0.0396s infinite normal none running grid-loader;
  }

  @keyframes grid-loader {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(0.5);
      opacity: 0.7;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
}
