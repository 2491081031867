@import 'assets/scss/mixin.scss';
@import 'assets/scss/variables.scss';

.form-otp-group {
  &__label {
    color: $text-color;

    @include fs-20;
    @include font-400;
  }

  &__input {
    display: flex;
    gap: 1rem;

    &__field {
      width: 2rem !important;
      height: 2.5rem !important;
      background: $light-grey-color;
      color: $black-color;
      border: 1px solid transparent;
      outline: 1px solid transparent;
      border-radius: 5px;
      padding: 0.5rem, 0.25rem;
      margin: 0 !important;

      @include fs-24;
      @include font-400;

      &::placeholder {
        color: $placeholder-color;

        @include fs-24;
        @include font-400;
      }

      &:focus {
        border: 1px solid transparent;
        outline: 1px solid transparent;
        border-radius: 10px;
        box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
      }
    }
  }

  &__resend {
    width: 30%;
  }

  .invalid-feedback {
    color: $error-color;
    display: block !important;

    @include fs-12;
    @include font-400;

    .error-icon {
      margin-right: 5px;
    }
  }

  .valid-feedback {
    color: $success-color;
    display: block !important;

    @include fs-12;
    @include font-400;

    .error-icon {
      margin-right: 5px;
    }
  }
}
