@import 'assets/scss/variables.scss';
@import 'assets/scss/mixin.scss';

.form-input-group {
  position: relative;

  .type-ahead-dropdown {
    &-menu {
      color: $text-color;
      width: 100%;
      min-width: unset;

      @include font-400;
      @include fs-14;
    }
  }

  &__input {
    &:read-only {
      box-shadow: none;
      border: 1px solid transparent;

      &:hover,
      &:focus {
        box-shadow: none;
      }
    }
  }
}
