@import 'assets/scss/variables.scss';
@import 'assets/scss/mixin.scss';

.sign-in-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .remember-me-label {
    color: $check-box-label-color;
  }

  .forgot-password {
    color: #555;
    text-align: right;
    cursor: pointer;
    text-decoration: none;

    @include fs-12;
    @include font-400;
  }

  &__sign-in-button {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  &__have-account {
    color: $input-color;

    @include fs-16;
    @include font-400;

    .sign-up-link {
      color: $link-color;
      cursor: pointer;
      text-decoration: none;

      @include font-600;
    }
  }
}
