@import 'assets/scss/variables.scss';
@import 'assets/scss/mixin.scss';

.app-header {
  height: 5rem;
  width: 100vw;
  background-color: $white-color;
  border-width: 0 0 3px;
  border-style: solid;
  border-image: linear-gradient(
    90deg,
    rgb(66 133 244 / 100%) 0%,
    rgb(77 90 188 / 100%) 100%
  );
  border-image-slice: 1;

  .active-header {
    color: #4285f4 !important;
  }

  .navbar {
    height: 100%;
  }

  .navbar-nav {
    height: 5rem;
  }

  &__logo {
    height: 5rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      width: 4.7174rem;
      height: 3.5rem;
    }
  }

  &__menu {
    margin-left: 1rem;
    width: 55%;
    height: 5rem;
    justify-content: flex-start;

    @include app-width(gap, 40);

    &__item,
    &__item > .nav-link,
    div#{&}__item {
      padding-left: 0 !important;
      padding-right: 0 !important;
      color: #000432;
      text-decoration: none;
      cursor: pointer;

      @include fs-16;
      @include font-600;

      &__submenu-item {
        color: $text-color;
        display: flex;
        width: 180px;
        height: 2rem;
        padding: 6px 12px;
        align-items: center;
        cursor: pointer;

        @include fs-12;
        @include font-400;

        &__link {
          color: $text-color;
          text-decoration: none;
          height: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;

          @include fs-12;
          @include font-400;
        }
      }
    }

    &__dropdown {
      .dropdown-toggle {
        &::after {
          display: none;
        }
      }

      .dropdown-menu {
        padding: 0;

        .dropdown-item {
          color: $text-color;
          height: 2rem;
          display: flex;
          align-items: center;

          @include fs-12;
          @include font-400;

          &:first-child {
            border-radius: 6px 6px 0 0;
          }

          &:last-child {
            border-radius: 0 0 6px 6px;
          }
        }

        .dropdown-divider {
          margin: 0;

          :last-child {
            display: none;
          }
        }
      }
    }
  }

  &__search {
    margin-left: 1rem;
    width: 25%;
    max-width: 280px;
    height: 2.5rem;
    position: relative;

    &__result {
      margin-left: 30px;
      top: 10px;
      min-width: 75%;
      background: $white-color;
      padding: 0.5rem;
      position: relative;
      border: 1px solid rgb(0 0 0 / 17.6%);
      border-radius: 6px;
      z-index: 100;

      .result-item {
        height: 2rem;
        color: $text-color;
        display: flex;
        align-items: center;
        white-space: break-spaces;

        @include fs-14;
      }
    }
  }

  &__notification {
    margin-left: 1rem;
    height: 2rem;
  }

  &__profile-dropdown {
    &.app-dropdown {
      background-color: transparent;

      .dropdown-menu {
        min-width: max-content;
      }

      &-toggle {
        padding: 0;
      }
    }
  }

  &__profile {
    cursor: pointer;
  }

  .result-item.highlighted {
    background-color: #c7c5c5; /* or any color you prefer */
  }
}

.loader-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; // Ensure it's on top of other elements
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none; // Ensure clicks pass through
}

.loader-square {
  width: 100px; // Adjust size as needed
  height: 100px; // Adjust size as needed
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent; // Ensures no background color
}

.loader-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent; // Transparent background
  z-index: 9998; // Ensure it's behind the loader but on top of other content
}
