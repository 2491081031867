@import 'assets/scss/variables.scss';
@import 'assets/scss/mixin.scss';

.budget-data-container {
  width: 100%;
  height: calc(100% - 60px);
  padding: 10px 0;
  overflow: auto;
  counter-reset: page;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @include scroll-bar-style;

  @media print {
    @page {
      size: 8.5in 11in;
      margin: 16mm;
    }

    .page-break {
      display: none;
    }
  }

  .print-layout {
    // width: 780px;
    width: 8.5in;
    background-color: $white-color;
    padding: 2rem;
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      justify-content: space-between;

      .page-counter::after {
        counter-increment: page;
        content: 'Page ' counter(page);
        font-weight: 600;
        font-size: 14px;
      }
    }

    &__title {
      text-align: center;
      padding: 10px 0;
      font-weight: 600;
      font-size: 20px;
    }

    &__settings {
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      font-size: 14px;

      .left-block {
        width: 50%;

        &__item {
          text-align: left;
        }
      }

      .right-block {
        width: 50%;

        &__item {
          text-align: right;
        }
      }
    }

    &__details {
      padding: 1rem 0;
      flex: 1;

      /* stylelint-disable */

      .budget-details-table,
      .budget-details-sheet-table {
        thead {
          tr {
            border: 1px solid #999;

            th {
              border: 0;

              font-weight: 600;
              font-size: 14px;
              white-space: nowrap;

              &:last-child {
                text-align: right;
              }
            }
          }
        }

        tfoot,
        tbody {
          tr {
            border: 0;
            border-right: 1px solid #999;
            border-left: 1px solid #999;

            &:last-child {
              border-bottom: 1px solid #999;
            }

            td {
              border: 0;

              font-size: 14px;

              &:last-child {
                text-align: right;
              }
            }
          }
        }

        tfoot {
          tr td {
            background-color: $link-color;
            color: $white-color;
          }
        }
      }

      .budget-details-sheet-table {
        .item-name-column {
          min-width: 70px;
          word-break: break-all;
        }

        tbody {
          tr {
            &.swimlane-row {
              td {
                text-align: left;
                background-color: $link-color;
                color: $white-color;

                font-weight: 600;
              }
            }

            &.line-item-row {
              td {
                text-align: left;
                background-color: #c6c7c8;
              }
            }
          }
        }
      }

      /* stylelint-enable */
    }

    &__footer {
      display: flex;
      align-items: flex-end;
      position: relative;
      bottom: 0;

      .left-section {
        width: 33%;
        color: $text-color;
        text-align: left;
        text-transform: uppercase;
        font-size: 11px;
      }

      .middle-section {
        width: 33%;
        color: $theme-color;
        text-align: center;
        align-self: flex-end;
        font-weight: 600;
        font-size: 11px;
      }

      .right-section {
        width: 34%;
        color: $text-color;
        text-align: right;
        font-size: 11px;
      }
    }
  }
}
