@import 'assets/scss/variables.scss';
@import 'assets/scss/mixin.scss';

.app-dropdown {
  background-color: $dark-grey-color;

  &-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    cursor: pointer;

    &-icon {
      vertical-align: unset;
      width: 1.5rem;
      height: 1.5rem;
      transition: all 0.25s;

      path {
        fill: $link-color;
      }
    }
  }

  &.show {
    .app-dropdown-menu-toggle-icon {
      transform: rotate(-180deg);
      transition: all 0.25s;
    }
  }

  &-menu {
    padding: 0 !important;

    .dropdown-item {
      color: $text-color;
      height: 2rem;
      display: flex;
      align-items: center;
      cursor: pointer;

      // overflow: hidden;
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // -webkit-line-clamp: 3;
      // -webkit-box-orient: vertical;
      // max-width: 100%;
      // text-wrap: wrap;

      @include fs-12;
      @include font-400;

      &:hover,
      &.active {
        background-color: rgb(66 133 244 / 50%);
        color: $white-color;
      }

      &:first-child {
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
      }

      &:last-child {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
      }
    }
  }
}
