@import 'assets/scss/mixin.scss';
@import 'assets/scss/variables.scss';

.account-settings {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;

  &__header {
    color: $theme-color;
    margin: 0;

    @include fs-24;
    @include font-600;
    @include app-width(padding-top, 24);
    @include app-width(padding-bottom, 24);

    &__back-icon {
      cursor: pointer;
    }
  }

  &__section {
    max-height: calc(100% - 80px);
    height: 92%;

    aside {
      border-right: 1px #000432 solid;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
      padding-bottom: 1rem;

      @include app-width(gap, 10);
    }

    &__menu {
      display: flex;
      flex-direction: column;
      max-height: 80%;
      overflow: hidden;
      overflow-y: auto;

      @include app-width(padding-right, 10);
      @include app-width(gap, 16);
      @include scroll-bar-style(40px);

      &__item {
        padding: 10px 12px;
        box-shadow: 0 2px 4px 0 rgb(66 133 244 / 16%);
        background-color: $white-color;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-decoration: none;
        cursor: pointer;

        @include app-width(gap, 10);

        &__label {
          color: $input-color;

          @include fs-14;
          @include font-400;
        }

        &__icon {
          height: 18px;
          width: 18px;

          > svg {
            width: 18px;
            height: 18px;
            vertical-align: unset;

            path {
              fill-opacity: 1;
            }
          }
        }

        &--active {
          background: linear-gradient(139deg, #4285f4 0%, #4d5abc 100%);

          > span {
            color: $white-color;

            svg path {
              fill: $white-color;
              opacity: 1;
            }
          }
        }
      }
    }

    &__content {
      height: 100%;
      overflow: hidden;
      overflow-y: auto;

      @include scroll-bar-style(40px);

      .content-section {
        padding-left: 1.5rem;
        height: 100%;
        display: flex;
        flex-flow: column;

        .content-header {
          color: $text-color;
          @include app-width(margin-bottom, 28);

          @include fs-24;
          @include font-600;
        }

        .content-body {
          height: 92%;
        }
      }
    }
  }
}
