@import 'assets/scss/variables.scss';
@import 'assets/scss/mixin.scss';

.social-login {
  .sign-in-via {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    width: 100%;
    position: relative;

    &__line {
      background: #202020;
      flex: 1;
      height: 1px;
      position: relative;
    }

    &__text {
      color: #202020;
      text-align: left;
      position: relative;

      @include fs-12;
      @include font-400;
    }
  }

  &__options {
    display: flex;
    justify-content: space-between;

    &__google,
    &__facebook,
    &__apple {
      background: #f6f7f9;
      border-radius: 0.5rem;
      border: 1px solid transparent;
      padding: 0.5rem 1.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 31.5%;
      max-height: 40px;
      position: relative;
      cursor: pointer;
    }
  }
}
