@import 'assets/scss/mixin.scss';
@import 'assets/scss/variables.scss';

.add-item {
  width: 100%;

  @include fs-12;
  @include font-400;

  &-input {
    &__container {
      background-color: $theme-color;
      width: 100%;
      display: flex;
      gap: 0.5rem;
      border-radius: 6px;
      padding: 0.75rem;
      align-items: center;

      @include app-width('height', 48);

      &__add-action {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white-color;
        border-radius: 50%;

        @include app-width('height', 32);
        @include app-width('width', 32);
      }

      > .add-item-input {
        &__control {
          flex: 1;
          width: 90%;

          @include app-width('min-height', 32);
          @include app-width('height', 32);
        }
      }
    }
  }
}
