@import 'assets/scss/variables.scss';
@import 'assets/scss/mixin.scss';

.budget-settings {
  .modal-dialog {
    max-width: 32%;
  }

  .modal-header {
    border: none;

    @include app-width(padding, 16);

    padding-bottom: 0;
  }

  .modal-footer {
    border: none;
    padding: 0;
    width: 100%;
    justify-content: flex-start;
  }

  &__title {
    @include font-600;
  }

  &__sub-title {
    color: #123051;
    margin-top: 0.5rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;

    @include fs-16;
    @include font-600;

    .created-date {
      color: $semi-grey-color;

      @include fs-14;
      @include font-400;

      &::before {
        content: ' ';
        display: inline-block;
        width: 4px;
        height: 4px;
        background-color: $dark-grey-color;
        border-radius: 50%;
        margin-right: 0.5rem;
      }
    }
  }

  &__body {
    &.modal-body {
      @include app-width(padding, 16);
      @include app-width(padding-top, 24);
    }

    &__header {
      color: $theme-color;
      margin-bottom: 1.5rem;

      @include fs-24;
      @include font-600;
    }

    &__form {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    &__action {
      margin-top: 1rem !important;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      margin-bottom: 2rem !important;
    }

    &__modified-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.75rem;
      margin: 0 !important;

      .budget-modified {
        color: #565556;

        @include fs-12;
        @include font-400;

        &-by {
          color: $link-color;
        }
      }

      .budget-duration {
        color: #565556;

        @include fs-12;
        @include font-400;

        .duration {
          color: $link-color;

          @include font-600;
          @include fs-14;
        }
      }
    }
  }
}
