@import 'assets/scss/variables.scss';
@import 'assets/scss/mixin.scss';

.share-budget {
  .modal-dialog {
    max-width: 38%;
    min-width: 400px;
  }

  .modal-header {
    border: none;

    @include app-width(padding, 16);

    padding-bottom: 0;
  }

  &__title {
    @include font-600;
  }

  &__sub-title {
    color: #123051;
    margin-top: 0.5rem;

    @include fs-16;
    @include font-600;
  }

  &__body {
    &.modal-body {
      @include app-width(padding, 16);
      @include app-width(padding-top, 24);
    }

    .email-list-label {
      color: $text-color;

      @include fs-20;
      @include font-400;
    }

    .permissions {
      position: relative;

      .current-access {
        text-transform: capitalize;
      }

      .budget-permissions {
        width: 100%;
        background-color: transparent;
        text-transform: capitalize;

        .app-dropdown-toggle {
          padding: 0;
          text-transform: capitalize;
          justify-content: flex-end;

          @include fs-12;

          svg {
            height: 1.125rem;
            width: 1.125rem;

            path {
              fill: #123051;
            }
          }
        }

        .app-dropdown-menu {
          // min-width: 100%;
          min-width: 150%;
          min-height: 50px;

          .dropdown-item {
            height: 1.5rem;
          }
        }
      }
    }

    .share-budget-container {
      display: flex;
      align-items: center;
      width: 100%;

      @include app-width(gap, 24);

      .email-list-container {
        border-radius: 5px;
        background: $light-grey-color;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 75%;
        min-height: 4rem;
        padding: 0.5rem;

        .tags-container {
          display: inline-flex;
          flex-wrap: wrap;
          align-self: flex-start;
          align-items: center;
          gap: 0.5rem;
          width: 100%;

          .tag {
            display: inline-flex;
            border-radius: 4px;
            background-color: $link-color;
            color: $white-color;
            height: 1.5rem;
            align-items: center;
            width: auto;
            gap: 0.5rem;
            padding: 4px;

            @include fs-12;

            .remove-icon {
              cursor: pointer;

              @include app-width(width, 16);
              @include app-width(height, 16);

              path {
                fill: $white-color;
              }
            }
          }

          .email-list-dropdown {
            flex-grow: 1;
            position: relative;

            .tags-input {
              border: 0;
              background: transparent;
              box-shadow: none;
              padding: 0;
              width: 100%;
              padding-left: 0.5rem;

              @include fs-12;

              &:focus {
                border: none;
                box-shadow: none;
              }

              &::placeholder {
                color: $semi-grey-color;

                @include fs-12;
              }
            }

            &-menu {
              color: $text-color;
              width: max-content;
              min-width: unset;
              padding: 4px 0;
              max-height: 125px;
              overflow: hidden;
              overflow-y: auto;
              top: 14px;

              @include scroll-bar-style;
              @include font-400;
              @include fs-14;
            }
          }
        }
      }
    }

    .button-wrapper {
      width: max-content;

      .share-budget-btn {
        @include app-width(width, 150);
      }

      .resend-invite-btn {
        margin-left: 1rem;
        height: 1.5rem;
        border-radius: 4px;
        border: 0;
        padding: 5px;
        width: 80px;

        @include fs-14;
      }
    }

    .collaborator-list {
      border-top: 1px solid black;
      margin-top: 1rem;
      padding: 1rem 0;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &__user-row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .user-name {
          display: flex;
          align-items: center;
          color: $text-color;
          flex: 1;
          gap: 5px;

          @include fs-14;

          .avatar-wrapper {
            background: #039be5;
            margin: 0;

            @include app-width(width, 32);
            @include app-width(height, 32);

            .avatar-icon {
              color: $white-color;
              background: #039be5;
            }
          }

          .avatar-placeholder {
            border-radius: 50%;
            border: 1px dotted $semi-grey-color;
            @include app-width(width, 32);
            @include app-width(height, 32);
          }
        }

        .permissions {
          text-align: right;

          @include fs-12;
        }
      }

      .invited-title {
        color: #123051;
        display: flex;
        align-items: center;

        @include fs-14;
        @include font-600;

        .line {
          border-top: 1px solid #000432;
          flex-grow: 1;
          margin-left: 10px;
        }
      }
    }
  }
}

.clipboard {
  font-size: 12px;
  line-height: 1.5;
  color: #000;
}

.copy {
  cursor: pointer;

  // font-size: 12px;

  line-height: 1.5;
  color: #000;
}
