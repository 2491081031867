@import 'assets/scss/variables.scss';
@import 'assets/scss/mixin.scss';

.modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 70%);
  z-index: 100;
  opacity: 1;

  .modal-header {
    border-bottom: 1px solid grey;
    padding: 0 10px;

    .modal-title {
      color: $theme-color;

      @include fs-24;
    }
  }

  .modal-content {
    padding: 10px;
    overflow: auto;
  }

  .modal-body {
    @include scroll-bar-style;
  }

  .close-icon {
    position: absolute;
    top: 24px;
  }

  .center {
    top: 0;
    animation: center 0.7s;
    transform: scale(1);

    // transition: transform .3s ease-out;
    // transform: translate(0,0);
  }

  @keyframes center {
    from {
      transform: translate(0, 0), scale(0);
      opacity: 0;
      z-index: -1;
      top: 100%;
      left: 100%;
    }

    to {
      transform: translate(-50%, -50%), scale(1);
      opacity: 1;
      z-index: 100;
      top: 50%;
      left: 50%;
    }
  }

  .modal-main {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    overflow: auto;
    display: flex;
    flex-direction: column;
    text-align: justify;
    text-justify: inter-word;
    box-sizing: border-box;
  }

  .full-screen {
    border-radius: 12px 12px 0 0;
    width: 100%;
    height: 95%;
    bottom: 0;
    top: unset;
    transform: translate(-50%, 0%);
    animation: show 0.2s linear;
  }

  @keyframes show {
    from {
      height: 0;
    }

    to {
      height: 80%;
    }
  }

  .header-icons {
    display: flex;
    align-items: center;
    margin-left: auto;

    .copy-link-icon {
      margin-right: 5px; // Adjust spacing as needed
      cursor: pointer;
    }

    .btn-close {
      // margin-left: 10px; // Adjust spacing as needed
      cursor: pointer;
    }
  }

  // Ensure header items are spaced appropriately
  //   .modal-header {
  //     display: flex;
  //     justify-content: space-between;
  //     align-items: center;
  //   }

  //   .header-icons {
  //     margin-left: auto;
  //   }
}
