@import 'assets/scss/variables.scss';
@import 'assets/scss/mixin.scss';

.comment-popup {
  .create-budget-body-header {
    margin-bottom: 1rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: #4d5abc;
    font-size: calc(100rem * 24 / 1440);
  }

  .modal-footer {
    border-top: none !important;
    padding-top: 0 !important;
  }

  .modal-dialog {
    max-width: 50%;

    .modal-content {
      padding: 0 !important;
    }
  }

  .comment-body {
    @include scroll-bar-style;

    word-wrap: break-word;
    overflow-y: auto;
    max-height: 300px;
    color: #123051;
    margin-top: 0.5rem;

    // font-size: calc(100vw * 16 / 1440);
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
  }

  #comment-text-area {
    @include scroll-bar-style;
  }

  &__body {
    &.modal-body {
      @include app-width(padding, 36);
    }

    &__header {
      color: $theme-color;
      margin-bottom: 1.5rem;

      @include fs-24;
      @include font-600;

      .budget-name {
        color: $error-color;
        white-space: break-spaces;
      }
    }

    &__form {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    &__action {
      margin-top: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
  }
}

.edit-save-btns {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  width: 25%;
  margin-top: 10px;
  margin-left: auto;
}

.comm-header-wrraper {
  display: flex;
  padding: 10px;
  gap: 10px;
  align-items: center;

  h1 {
    margin-bottom: 0 !important;
  }

  .edit-icon {
    cursor: pointer;
  }
}
