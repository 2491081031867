@import 'assets/scss/variables.scss';
@import 'assets/scss/mixin.scss';

.app-chip {
  display: flex;
  width: auto;
  white-space: nowrap;
  flex-wrap: nowrap;
  flex-shrink: 0;
  min-width: 75px;
  height: 2rem;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: $text-color;
  background-color: $white-color;
  box-shadow: 0 2px 4px 0 rgb(0 65 191 / 16%);
  border-radius: 100px;
  padding: 8px 12px;
  cursor: pointer;

  @include fs-16;
  @include font-600;

  &--disabled {
    cursor: not-allowed;
  }

  svg {
    vertical-align: unset;
    width: 1.125rem;
    height: 1.125rem;
  }
}
