@import 'assets/scss/variables.scss';
@import 'assets/scss/mixin.scss';

.change-password {
  padding: 0 1.5rem;

  &__header {
    @include app-width(margin-bottom, 28);
  }

  &__form {
    width: 30%;
    display: flex;
    flex-direction: column;

    @include app-width(gap, 24);

    &__input {
      background-color: $white-color !important;
    }

    .input-group-text {
      background-color: $white-color !important;
    }

    &__action {
      display: flex;
      gap: 1rem;

      @include app-width(margin-top, 20);
    }
  }
}
