@import 'assets/scss/variables.scss';
@import 'assets/scss/mixin.scss';

.budget-data-container {
  width: 100%;
  height: calc(100% - 60px);
  padding: 10px 0;
  overflow: auto;
  counter-reset: page;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @include scroll-bar-style;

  @media print {
    @page {
      size: 8.5in 11in;
      margin: 16mm;
    }

    .page-break {
      display: none;
    }
  }

  .aicp-print-layout {
    // width: 780px;
    width: 8.5in;
    background-color: $white-color;
    padding: 2rem;
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      justify-content: space-between;

      .page-counter::after {
        counter-increment: page;
        content: 'Page ' counter(page);

        @include font-600;
        @include fs-14;
      }
    }

    &__title {
      text-align: center;
      padding: 10px 0;

      @include font-600;
      @include fs-20;
    }

    &__settings {
      display: flex;
      flex-direction: column;
      border: 1px solid black;

      @include font-700;
      @include fs-14;

      .job-info {
        min-height: 80px;

        &-header {
          background-color: $link-color;
          color: white;
          width: 100%;
          height: auto;
          padding: 4px;
        }

        &-content {
          padding: 0 4px 10px;
          display: flex;

          .left-block {
            width: 33%;

            &__item {
              padding: 2px 0 0;
              text-align: left;
            }
          }

          .center-block {
            width: 33%;

            &__item {
              padding: 2px 0 0;
              text-align: left;
            }
          }

          .right-block {
            width: 33%;

            &__item {
              padding: 2px 0 0;
              text-align: left;
            }
          }
        }
      }

      .production-info {
        min-height: 200px;

        &-header {
          background-color: $link-color;
          color: white;
          width: 100%;
          height: auto;
          padding: 4px;
        }

        &-content {
          padding: 0 4px 10px;
          display: flex;

          .left-block {
            width: 33%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .sub-block {
              &__item {
                padding: 2px 0 0;
                text-align: left;
              }
            }
          }

          .center-block {
            width: 33%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .sub-block {
              &__item {
                padding: 2px 0 0;
                text-align: left;
              }
            }
          }

          .right-block {
            width: 33%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .sub-block {
              &__item {
                padding: 2px 0 0;
                text-align: left;
              }
            }
          }
        }
      }
    }

    &__details {
      flex: 1;

      /* stylelint-disable */

      .budget-details-table,
      .budget-details-sheet-table {
        thead {
          tr {
            border: 1px solid black;

            th {
              border: 0;
              background-color: $link-color;
              color: white;
              white-space: nowrap;

              @include fs-14;
              @include font-600;

              &:last-child {
                text-align: right;
              }
            }
          }
        }

        tfoot,
        tbody {
          tr {
            border-bottom: 1px solid black;
            border-right: 1px solid black;
            border-left: 1px solid black;

            td {
              border: 0;

              @include fs-14;

              &:last-child {
                text-align: right;
              }
            }
          }
        }

        tfoot {
          tr {
            border: 0;

            td {
              background-color: $link-color;
              color: $white-color;
              border: 0;
            }
          }
        }
      }

      .budget-details-sheet-table {
        tbody {
          tr {
            &.swimlane-row {
              td {
                text-align: left;
                background-color: $link-color;
                color: $white-color;

                @include font-600;
              }
            }

            &.line-item-row {
              td {
                text-align: left;
                background-color: #c6c7c8;
              }
            }
          }
        }
      }

      /* stylelint-enable */
    }

    &__footer {
      display: flex;
      align-items: flex-end;

      .left-section {
        width: 33%;
        color: $text-color;
        text-align: left;
        text-transform: uppercase;

        @include fs-14;
      }

      .middle-section {
        width: 33%;
        color: $theme-color;
        text-align: center;
        align-self: flex-end;

        @include fs-14;
        @include font-600;
      }

      .right-section {
        width: 34%;
        color: $text-color;
        text-align: right;

        @include fs-14;
      }
    }
  }
}
