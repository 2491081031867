@import 'assets/scss/mixin.scss';
@import 'assets/scss/variables.scss';

.print-layout-container {
  width: 100%;
  height: 100%;
  padding: 10px 0;
  overflow: hidden;

  .print-actions {
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-end;
    position: relative;
    gap: 8px;
    align-items: center;

    &__back-icon {
      left: 0;
      position: absolute;
      cursor: pointer;
    }

    .button-wrapper {
      width: 150px;

      .button-label {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: 8px;

        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }

  .print-filters {
    display: flex;
    justify-content: flex-start;
    gap: 8px;

    .form-check-input {
      &:checked {
        background-color: $link-color !important;
        border-color: $link-color !important;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}
