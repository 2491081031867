@import 'assets/scss/mixin.scss';
@import 'assets/scss/variables.scss';

.user-profile {
  &__header {
    display: flex;
    justify-content: space-between;

    &__actions {
      width: max-content;
      display: flex;
      gap: 0.75rem;

      .user-profile-save-btn,
      .user-profile-cancel-btn,
      .user-profile-edit-btn {
        padding: 10px;
        width: 150px;
      }
    }
  }

  &__content {
    &__col {
      display: flex;
      flex-direction: column;

      @include app-width(gap, 24);

      .personal-info,
      .address-info,
      .profile-image,
      .contact-info {
        width: 100%;
        display: flex;
        flex-direction: column;

        @include app-width(gap, 24);

        .form-label {
          &__optional-text {
            color: $semi-grey-color;

            @include fs-14;
            @include font-400;
          }
        }

        .help-text {
          display: flex;
          gap: 5px;
          margin-top: 8px;
          align-items: center;

          &__icon {
            vertical-align: unset;

            @include app-width(width, 16);
            @include app-width(height, 16);

            path {
              fill: $black-color;
            }
          }

          &__content {
            color: $black-color;

            @include fs-12;
            @include font-400;
          }
        }
      }

      .profile-image {
        &__content {
          display: flex;
          align-items: center;
          position: relative;

          @include app-width(gap, 10);

          &__thumbnail {
            border-radius: 50%;

            svg {
              @include app-width(width, 80);
              @include app-width(height, 80);

              vertical-align: unset;
            }

            img {
              @include app-width(width, 80);
              @include app-width(height, 80);

              border-radius: 50%;
            }
          }

          &__upload {
            width: 100%;

            .cb-app-button {
              border-color: $button-color;
              color: $button-color;
              max-width: 160px;
              border-radius: 6px;

              @include app-width(padding, 20);
              @include app-width(padding-top, 12);
              @include app-width(padding-bottom, 12);

              .upload-label {
                display: flex;
                justify-content: center;
                gap: 8px;

                svg {
                  @include app-width(width, 18);
                  @include app-width(height, 18);

                  vertical-align: unset;
                }
              }
            }

            .no-image-text {
              color: $semi-grey-color;
              margin-top: 8px;

              @include fs-16;
              @include font-400;
            }
          }
        }
      }
    }
  }
}
