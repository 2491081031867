@import 'assets/scss/mixin.scss';
@import 'assets/scss/variables.scss';

.form-select-group {
  &__label {
    color: $text-color;

    @include fs-20;
    @include font-400;
  }

  .select-dropdown {
    height: 2.5rem;
    background-color: $light-grey-color;
    color: $black-color;

    /* stylelint-disable selector-class-pattern */
    &:has(.select-dropdown__value-container--is-multi) {
      min-height: 2.5rem;
      height: auto;
    }

    /* stylelint-enable selector-class-pattern */

    &__control {
      background-color: $light-grey-color;
      color: $black-color;

      // border: 1px solid transparent;
      border-radius: 5px;

      @include fs-16;
      @include font-400;

      &__placeholder {
        color: $placeholder-color;

        @include fs-16;
        @include font-400;
      }
    }

    &__option {
      padding: 4px 12px;
    }
  }

  .invalid-feedback {
    color: $error-color;
    display: block !important;

    @include fs-12;
    @include font-400;

    .error-icon {
      margin-right: 5px;
    }
  }
}
