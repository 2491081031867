@import 'assets/scss/variables.scss';
@import 'assets/scss/mixin.scss';

.sign-up-form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .sign-up-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    &__accept-tos {
      color: $check-box-label-color;

      @include fs-12;
      @include font-400;

      &__link {
        color: $link-color;
        cursor: pointer;
      }
    }

    .form-otp-group {
      margin-top: -0.5rem;
    }

    .remember-me-label {
      color: $check-box-label-color !important;
    }

    &__sign-up-button {
      margin-top: 2rem;
      margin-bottom: 1rem;
    }

    &__have-account {
      color: $input-color;

      @include fs-16;
      @include font-400;

      .sign-in-link {
        color: $link-color;
        cursor: pointer;

        @include font-600;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;

      &__label {
        color: $text-color;

        @include fs-20;
        @include font-400;
      }

      &__note {
        color: $semi-grey-color;

        @include fs-14;
        @include font-400;

        &__email {
          color: $link-color;
        }

        .otp-digit {
          color: $input-color;
        }
      }
    }

    &__action {
      display: flex;
      gap: 1rem;
    }
  }
}
