@import 'assets/scss/variables.scss';

.upload-container {
  &__file-accept {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    padding-left: 1rem;
    color: #34a853;
    list-style: none;
  }

  &__file-reject,
  &__error {
    color: $error-color;
    word-wrap: break-word;
    white-space: break-spaces;
    word-break: break-all;
    margin-top: 0.5rem;
    font-size: 0.75rem;
    padding-left: 1rem;
    list-style: none;
  }

  &__file-type-info {
    color: $semi-grey-color;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    margin-top: 1.875rem;
    margin-bottom: 0.875rem;
  }
}
