@import 'assets/scss/mixin.scss';
@import 'assets/scss/variables.scss';

.form-password-group {
  &__label {
    color: $text-color;

    @include fs-20;
    @include font-400;
  }

  & > .input-group > #{&}__input {
    height: 2.5rem;
    background: $light-grey-color;
    color: $black-color;
    border: 1px solid transparent;
    border-radius: 5px;

    @include fs-16;
    @include font-400;

    &::placeholder {
      color: $placeholder-color;

      @include fs-16;
      @include font-400;
    }

    &::-ms-reveal,
    &::-ms-clear {
      display: none;
    }
  }

  & > .input-group > .input-group-text {
    border: 1px solid transparent;
    border-radius: 5px;
  }

  .invalid-feedback {
    color: $error-color;

    @include fs-12;
    @include font-400;

    .error-icon {
      margin-right: 5px;
    }
  }
}
