@import 'assets/scss/variables.scss';
@import 'assets/scss/mixin.scss';

.delete-confirm-modal {
  .modal-dialog {
    max-width: 30%;
  }

  &-body-wrapper {
    &.modal-body {
      @include app-width(padding, 36);
    }

    &__image-container {
      display: flex;
      margin-bottom: 20px;
      justify-content: center;

      &__icon {
        width: 70px;
        height: 70px;
        background-color: $light-grey-color;
        border-radius: 50%;
        font-weight: 400;
      }
    }

    &__title {
      color: $text-color;
      text-align: center;

      @include fs-20;
      @include font-600;
    }

    &__text {
      text-align: center;
      margin-top: 10px;
      margin-bottom: 20px;
      font-weight: 400;
      color: $text-color;

      @include fs-16;
      @include font-400;
    }

    .action-wrapper {
      gap: 20px;

      &__cancel-btn {
        width: 154px;
        height: 54px;
      }

      &__confirm-btn {
        width: 154px;
        height: 54px;
        background-color: $error-color;
      }
    }
  }
}
