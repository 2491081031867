@import 'assets/scss/variables.scss';
@import 'assets/scss/mixin.scss';

.move-budget {
  .modal-dialog {
    max-width: 40%;
  }

  .modal-content {
    min-height: 400px;
    max-height: 600px;
    overflow-y: auto;
    @include scroll-bar-style;

    scroll-behavior: smooth;
  }

  &__body {
    &.modal-body {
      @include app-width(padding, 15);
    }

    &__header-container {
      display: flex;
      justify-content: space-between;

      .remove-icon {
        cursor: pointer;
      }
    }

    &__header {
      color: $theme-color;
      margin-bottom: 1.5rem;

      @include fs-24;
      @include font-600;

      .budget-name {
        color: $error-color;
        white-space: break-spaces;
      }
    }

    &__form {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    &__action {
      margin-top: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
  }
}

.folder-main-head {
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: 600;
  color: #222;
}

.folder-head {
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 600;
  color: #666;
  margin-left: 5px;
}

.folders-listing {
  margin-top: 10px;
  overflow-y: auto;
  padding-right: 5px;
  height: 180px;

  .folder-item-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #0000003d;
    height: 56px;
  }

  .folder-item-wrapper:last-child {
    // border-bottom: none;
  }

  .folder-name-wrapper {
    width: 50%;
    display: flex;

    // align-items: center;
    gap: 5px;
  }

  .folder-name-wrapper svg {
    width: 24px;

    /* Adjust the size as needed */
    height: 24px;

    /* Adjust the size as needed */
  }

  .folder-item {
    cursor: pointer;
    width: 100%;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;

    // line-height: 20px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #222;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .folder-created-date {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;

    // line-height: 20px;
    letter-spacing: -0.02em;
    text-align: right;
    color: #222;
    width: 45%;
  }
}

.move-here-btn {
  margin-top: 30px;

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: inherit;
  }

  button {
    width: 120px !important;
  }
}

.selected-budgets-list {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
  flex-wrap: wrap;

  .budgets-name-wrapper {
    display: flex;
    align-items: center;
    gap: 2px;

    // max-width: 20%;
  }

  .blue-dot {
    flex-shrink: 0;
    background-color: #4285f4;
    border-radius: 50%;
    height: 4px;
    width: 4px;
  }

  .budget-name {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #444;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.selected-budgets,
.selected-folder {
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #222;
}

.selected-budget-message {
  margin-bottom: 10px;
}

.selected-budget-message,
.selected-folder {
  font-size: 14px;
}

.folder-path {
  gap: 5px;
  cursor: pointer;
}
