@import 'assets/scss/variables.scss';
@import 'assets/scss/mixin.scss';

table.detailed-view-table {
  // height: 100%;
  // width: 100%;
  // margin: 0;
  // min-width: 1140px;
  // table-layout: auto;
  // overflow-y: auto;
  // border-collapse: separate;
  // border-spacing: 0;
  border-collapse: initial;
  border-spacing: 0;

  // height: 100%;
  margin: 0;
  min-width: 1140px;
  height: fit-content;
  position: relative;

  /* overflow-y: auto; */
  table-layout: auto;
  width: 100%;

  /* stylelint-disable */

  thead {
    top: 0;
    position: sticky;
    z-index: 10;

    th {
      border: 1px solid #999;
      background: #d6d6d6;
      color: $black-color;
      vertical-align: middle;
      z-index: 10;
      top: 0;
      position: sticky;
      position: -webkit-sticky;

      @include fs-14;

      &:last-child {
        border-right: 0;

        div {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }

  tbody {
    tr {
      height: 2.5rem;

      td {
        overflow-wrap: anywhere;
        height: 2.5rem !important;
        padding: 0 5px !important;
        box-shadow: none;
        display: relative;
        z-index: 5;

        &:last-child {
          border-right: 0;
          height: 100%;
        }

        .col-content {
          display: flex;
          // align-items: baseline;
          height: 2.5rem;
          width: 100%;

          &.has-error {
            height: auto;
          }

          .form-input-group {
            width: 100%;
            // display: flex;
            // gap: 10px;
          }

          .form-input-group-expanded {
            width: 100%;
            display: flex;
            gap: 10px;
          }

          .form-control:focus {
            width: 90% !important;
          }

          .prefix {
            display: inline-flex;
            color: $text-color;
            height: 2rem;
            align-items: center;
            padding: 0.376rem 0;

            @include fs-14;
          }

          input {
            height: 2rem;
            background: rgba(255, 255, 255, 0);
            color: $black-color;
            border: none;
            padding-left: 1px;
            padding-right: 1px;
            max-width: 100%;
            width: 100%;

            @include fs-14;

            &::placeholder {
              color: $placeholder-color;
            }
          }

          .app-dropdown {
            background: rgba(255, 255, 255, 0);
            justify-self: flex-end;
            align-self: center;

            &-toggle {
              padding: 0;
              background: rgba(255, 255, 255, 0);
            }
          }
        }
      }

      &:last-child {
        height: 100%;

        td,
        td:focus {
          height: 100%;
          background-color: $white-color !important;
        }
      }

      &:nth-of-type(odd) > * {
        border: 1px solid #999;
        border-top: 0;
        color: $text-color;
        background-color: #f6f6f6;
      }

      &:nth-of-type(even) > * {
        border: 1px solid #999;
        border-top: 0;
        color: $text-color;
        background-color: $white-color;
      }
    }
  }

  tfoot {
    bottom: 0;
    position: sticky;
    position: -webkit-sticky;
    z-index: 10;

    tr {
      td {
        border-top: 1px solid #999;
        background: #d6d6d6;
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        z-index: 10;

        &:first-child {
          border-right: 1px solid #d6d6d6;
          border-left: 1px solid #999;
          text-align: right;
        }

        &:last-child {
          border-right: 0;
          border-left: 1px solid #d6d6d6;
        }
      }
    }
  }

  /* stylelint-enable */

  /* Override styles for draggable items */

  // .draggable-item {
  //   transition: none !important;
  //   transform: none !important;
  //   background-color: inherit !important;
  //   /* Prevent size compression */
  //   width: 100% !important;
  //   display: table-row !important;
  // }
}
