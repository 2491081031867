@import 'assets/scss/variables.scss';
@import 'assets/scss/mixin.scss';

.auth-layout {
  background: $off-white-color;
  width: 100rem;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;

  &__background {
    width: 51%;
    height: 100%;
    left: -12%;
    background: linear-gradient(
      200.51deg,
      rgb(86 148 239) 0%,
      rgb(77 90 188) 100%
    );
    transform: skewX(-15deg) scale(1.15, 1);
    position: absolute;
  }

  &__cinema-tape {
    width: 51%;
    height: 100%;
    left: -10%;
    transform: skewX(-15deg) scale(1.15, 1);
    position: absolute;

    svg {
      width: 54%;
      height: 100%;
      float: right;
    }
  }

  &__container {
    width: 100%;
    height: 100%;

    @media (width <=768px) {
      overflow-y: auto;
    }

    &__left-section {
      height: 100%;
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2rem;

      @media (width <=768px) {
        overflow-y: auto;
        height: 75%;
      }

      .app-logo {
        width: 24%;
        aspect-ratio: 1.25/1;
        background: $white-color;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 0;
        left: 0;

        svg {
          width: 58.2%;
          height: 58.5%;
        }
      }

      .app-welcome-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex: 1;

        &--signup {
          justify-content: center;
          height: 100%;
          position: absolute;
          top: 0;
        }

        //  gap: 1rem;

        @media (width >=768px) {
          overflow-y: auto;
        }

        @include scroll-bar-style;

        .app-welcome-text {
          height: auto;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 1.5rem;

          &__title {
            color: $white-color;
            text-align: center;

            @include fs-24;
            @include font-400;
          }

          &__name {
            color: $white-color;
            text-align: center;
            margin-bottom: 1rem;

            @include fs-32;
            @include font-600;
          }
        }

        .app-intro {
          width: 100%;

          // max-height: 50%;

          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          &__text {
            width: 100%;
            text-align: center;
            color: $white-color;
            @include fs-24;
            @include font-600;
          }

          &__video {
            height: 300px;
            width: 70%;
            margin: 0 auto;

            &--signin {
              width: 70%;
              height: 300px;

              // aspect-ratio: 1/0.635;

              margin: 0 auto;

              @media (width <=768px) {
                height: 200px;
              }
            }
          }
        }
      }

      .app-welcome {
        height: auto;
        width: 65%;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        align-items: center;
        margin-top: 1rem;

        &__description {
          color: $white-color;
          text-align: left;

          @include fs-24;
          @include font-400;

          ul {
            text-align: left;
            padding: 0;

            @include app-width(margin-top, 24);
            @include fs-20;
            @include font-400;

            li {
              display: flex;
              gap: 10px;
              align-items: center;
              justify-content: flex-start;

              @include app-width(margin-top, 16);

              svg {
                vertical-align: unset;
                width: 2rem;
                height: 2rem;
              }
            }
          }
        }
      }
    }

    &__right-section {
      height: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (width <=768px) {
        overflow-y: auto;
        height: 70%;
      }

      .form-container {
        height: auto;
        max-height: 80%;
        overflow: hidden;
        overflow-y: auto;
        position: relative;
        background: $white-color;
        border-radius: 20px;
        box-shadow: 0 8px 16px 0 rgb(0 65 191 / 16%);
        padding: 7.8% 8.8%;

        @include scroll-bar-style(40px);

        .header {
          color: $theme-color;

          @include fs-24;
          @include font-600;
        }
      }
    }
  }
}

.mobile-view-logo {
  @media (width <=450px) {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-top: 60%;
    margin-bottom: -80%;
  }
}

.mobile-view {
  @media (width <=450px) {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-align: center;
    color: black;
    background: linear-gradient(200.51deg, white 0%, white 100%);
  }
}
