@import './variables.scss';

@mixin font-400 {
  font-family: $font-family;
  font-weight: 400;
}

@mixin font-500 {
  font-family: $font-family;
  font-weight: 500;
}

@mixin font-500i {
  font-family: $font-family;
  font-style: italic;
  font-weight: 500;
}

@mixin font-600 {
  font-family: $font-family;
  font-weight: 600;
}

@mixin font-700 {
  font-family: $font-family;
  font-weight: 700;
}

@mixin fs-12 {
  @include app-width('font-size', 12);
  @include app-width('line-height', 15);
}

@mixin fs-13 {
  @include app-width('font-size', 13);
  @include app-width('line-height', 17);
}

@mixin fs-14 {
  @include app-width('font-size', 14);
  @include app-width('line-height', 18);
}

@mixin fs-16 {
  @include app-width('font-size', 16);
  @include app-width('line-height', 20);
}

@mixin fs-18 {
  @include app-width('font-size', 18);
  @include app-width('line-height', 22);
}

@mixin fs-20 {
  @include app-width('font-size', 20);
  @include app-width('line-height', 28);
}

@mixin fs-24 {
  @include app-width('font-size', 24);
  @include app-width('line-height', 28);
}

@mixin fs-32 {
  @include app-width('font-size', 32);
  @include app-width('line-height', 36);
}

@mixin fs-body {
  @include fs-16;
}

@mixin app-width($property, $value) {
  #{$property}: calc(100rem * #{$value} / 1440);

  @media (width >=1441px) {
    #{$property}: #{$value}px;
  }

  @media (width <=768px) {
    #{$property}: #{$value}px;
  }
}

// @mixin scroll-bar-style($height: 40px) {
//   scrollbar-width:thin;
//   scrollbar-color: #e0e0e0;

//   &::-webkit-scrollbar {
//     width: 18px;
//     height: 5px;
//   }

//   &::-webkit-scrollbar-track {
//     border-radius: 100px;
//   }

//   &::-webkit-scrollbar-thumb {
//     background-color: #e0e0e0;
//     border-radius: 100px;
//     height: $height;
//     border: 1px solid transparent;
//     background-clip: content-box;
//   }
// }

@mixin scroll-bar-style($height: 40px) {
  // Customize the scrollbar width for webkit browsers
  &::-webkit-scrollbar {
    // width: 10px; // Increase the width to make it thicker
  }

  // Customize the scrollbar track
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  // Customize the scrollbar thumb
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
  }

  // Customize the scrollbar thumb on hover
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

@mixin app-background(
  $angle: 90deg,
  $startColor: $link-color 0%,
  $endColor: $theme-color 100%
) {
  background: linear-gradient($angle, $startColor, $endColor);
}
