@import 'assets/scss/variables.scss';
@import 'assets/scss/mixin.scss';

.forgot-password-form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .forgot-password-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    &__confirm-button {
      margin-top: 2rem;
      margin-bottom: 1rem;
    }

    .form-otp-group {
      margin-top: -0.5rem;
    }

    &__item {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;

      &__label {
        color: $text-color;

        @include fs-20;
        @include font-400;
      }

      &__note {
        color: $semi-grey-color;

        @include fs-14;
        @include font-400;

        &__email {
          color: $link-color;
        }

        .otp-digit {
          color: $input-color;
        }
      }
    }

    &__action {
      display: flex;
      gap: 1rem;
    }
  }
}
