@import 'assets/scss/mixin.scss';
@import 'assets/scss/variables.scss';

.form-phone-group {
  &__label {
    color: $text-color;

    @include fs-20;
    @include font-400;
  }

  .react-international-phone-input-container {
    gap: 12px;

    .react-international-phone-country-selector {
      height: 2.5rem;
      background: $light-grey-color;
      color: $black-color;
      border: 1px solid transparent;
      border-radius: 5px;
      width: 4rem;

      &-button {
        background: $light-grey-color;
        color: $black-color;
        border: none;
        border-radius: 5px;
        margin: 0;
        width: 100%;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
    }

    .react-international-phone-input {
      height: 2.5rem;
      background: $light-grey-color;
      color: $black-color;
      border: 1px solid transparent;
      border-radius: 5px;
      width: 90%;

      @include fs-16;
      @include font-400;

      &::placeholder {
        color: $placeholder-color;

        @include fs-16;
        @include font-400;
      }
    }
  }

  .invalid-feedback {
    color: $error-color;
    display: block !important;

    @include fs-12;
    @include font-400;

    .error-icon {
      margin-right: 5px;
    }
  }
}
