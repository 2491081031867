.app-backdrop-wrapper {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;

  .app-backdrop {
    background-color: rgb(0 0 0);
    opacity: 0.5;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
  }

  .app-backdrop-content {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 15;
    background-color: rgb(0 0 0 / 70%);
  }
}
