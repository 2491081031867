@import 'assets/scss/variables.scss';
@import 'assets/scss/mixin.scss';

.logout-modal {
  .modal-dialog {
    max-width: 30%;
  }

  &__body {
    &.modal-body {
      @include app-width(padding, 36);
    }

    &__header {
      color: $theme-color;
      margin-bottom: 0.75rem;

      @include fs-24;
      @include font-600;
    }

    &__text {
      color: $semi-grey-color;
      margin-bottom: 2rem;

      @include fs-16;
      @include font-600;
    }

    &__action {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
  }
}
