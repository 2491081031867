@import 'assets/scss/variables.scss';
@import 'assets/scss/mixin.scss';

.avatar-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  border: 1px solid transparent;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  padding: 1px;
  background: linear-gradient(
    135deg,
    rgb(66 133 244 / 100%) 0%,
    rgb(77 90 188 / 100%) 100%
  );

  .avatar-icon {
    color: $white-color;
    background-color: $dark-grey-color;
    border-radius: 50%;
    width: 95%;
    height: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}
