$font-family: 'Open Sans', sans-serif;
$white-color: #fff;
$black-color: #000;
$off-white-color: #f6f7f9;
$text-color: #222;
$input-color: #444;
$text-info-color: #1b91ff;
$error-color: #bf0000;
$success-color: #34a853;
$theme-color: #4d5abc;
$dark-grey-color: #999;
$light-grey-color: #f7f8f9;
$semi-grey-color: #666;
$link-color: #4285f4;
$check-box-color: #123051;
$check-box-label-color: #123051;
$button-color: #ff9912;
$placeholder-color: '#b7b4b4';
