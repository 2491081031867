@import 'assets/scss/variables.scss';
@import 'assets/scss/mixin.scss';

.search-input-wrapper {
  border: 1px solid $semi-grey-color;
  border-radius: 100px;

  &:focus-within {
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  }

  &:has(.is-invalid):focus-within {
    border-color: rgb(220 53 69);
    box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 25%);
  }

  .input-group-text {
    border-radius: 100px;
    border: none;
    background-color: transparent;
    padding-right: 0;
  }

  .form-control {
    border-radius: 100px;
    border: none;
    background-color: transparent;

    &:focus {
      box-shadow: none !important;
    }

    &::placeholder {
      color: #565556;

      @include font-400;
      @include fs-16;
    }
  }

  .close-icon {
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
  }
}
