@import 'assets/scss/mixin.scss';
@import 'assets/scss/variables.scss';

.checkbox-wrapper {
  > .form-check {
    .form-check-label {
      color: $check-box-label-color;

      @include fs-12;
      @include font-400;
    }

    .form-check-input {
      width: 18px !important;
      height: 18px !important;

      &:checked {
        background-color: $check-box-color !important;
        border-color: $check-box-color !important;
      }
    }

    .invalid-feedback {
      color: $error-color;

      @include fs-12;
      @include font-400;

      .error-icon {
        margin-right: 5px;
      }
    }
  }
}
