@import 'assets/scss/mixin.scss';

.button-wrapper {
  width: 100%;

  // width: max-content;

  > .cb-app-button {
    text-align: center;
    color: $white-color;
    padding: 10px;
    width: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include fs-16;
    @include font-700;

    &.button-primary {
      background: linear-gradient(135deg, #ff9912 0%, #ff8000 100%);
      box-shadow: 0 8px 16px 0 rgb(189 96 2 / 24%);
      border-color: #ff8000;

      &:active,
      &:hover {
        background: linear-gradient(135deg, #ff9912 0%, #ff8000 100%);
        border-color: #ff8000;
      }
    }

    &.button-red-outlined {
      color: $error-color;
      border-radius: 10px;
      border: 1px solid $error-color;
      background: $off-white-color;
    }

    &.button-grey-outlined {
      color: $text-color;
      border-radius: 10px;
      border: 1px solid $dark-grey-color;
      background: $white-color;
    }

    &.button-grey {
      border-radius: 100px;
      background: $dark-grey-color;
    }

    &:disabled {
      opacity: 0.3;
    }
  }
}
